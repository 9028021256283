<template>
  <div class="d-flex align-items-center back-jump">
    <Gbutton @click.native="handleBackArrowClick()" :href="obj.link" class="btn-primary btn-w-med back-arrow">
      <BackArrow />{{ i18n['back-jump-location'].tcBack }}
      <span>({{ obj.location }})</span>
    </Gbutton>
    <span class="bj-location">{{ obj.location }}</span>
    <QuickJump @location_changed="handleLocationChange($event)" v-if="vis" :i18n="i18n"></QuickJump>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import Gbutton from '@/components/Gbutton.vue'
import QuickJump from '@/components/quick-jump/QuickJumpLocations.vue'
import BackArrow from '@/assets/svgs/back-arrow.vue'

export default {
  props: {
    obj: Object,
    vis: Boolean,
    i18n: {
      type: Object,
      default: () => {
        return {
          'back-jump-location': {
            tcBack: 'Back',
          },
          'quick-jump-locations': {},
        }
      },
    },
  },
  computed: {},
  methods: {
    ...mapActions({
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedOrgTypeKey: 'user/setSelectedOrgTypeKey'
    }),
    handleBackArrowClick() {
      this.$router.push(this.obj.link)
    },
    async handleLocationChange(evt) {
      let evtParts = evt.split('|')
      await this.setSelectedLocationKey(evtParts[0])
      await this.setSelectedOrgTypeKey(evtParts[1])
      await Promise.all([this.$emit('reload_host')])
    },
  },
  components: {
    Gbutton: Gbutton,
    QuickJump: QuickJump,
    BackArrow: BackArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

@include breakpoint(sm) {
  .back-jump {
    flex-wrap: wrap;

    form {
      select {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
}

.bj-location {
  font-size: 22px;
  font-family: $DIN;
  color: #000;
  margin-left: 20px;
  text-transform: uppercase;
  flex: 1 1 auto;

  @include breakpoint(sm) {
    display: none;
  }
}

.back-arrow {
  @include breakpoint(sm) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  span {
    display: none;

    @include breakpoint(sm) {
      padding-left: 5px;
      display: inline-block;
    }
  }

  svg {
    margin-right: 15px;
  }
}

footer .back-arrow {
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}
</style>
