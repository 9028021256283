<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcLocationContacts }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ translations.tcContacts }}</h2>
          <b-button variant="primary" v-if="iCanSeeAny(secured_contact_add_controls)" @click="handleAddContactClick">{{
            translations.tcAddContact
          }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if='translations.components'>
          <ServiceCard v-for="(contact, index) in locationContacts" :i18n="translations.components" :key="index"
            :obj="contact" :secure_edit_keys="secured_contact_edit_controls"
            :secure_delete_keys="secured_contact_delete_controls" @edit_requested="handleContactEditRequest(contact)"
            @profile_requested="handleContactProfileRequest(contact)" @delete_approved="handleDeleteRequest(contact)">
          </ServiceCard>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpLocation.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'location-contacts',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_contact_add_controls: {
        add_aux_facility_contact_button: '7ca96636-dde0-4ad8-81d0-8c8531d54a92',
        add_scripture_placement_facility_contact_button: 'c0078946-9fef-4024-a3cd-99c2da367454',
      },
      secured_contact_edit_controls: {
        edit_aux_facility_contact_button: '7b385aa9-a737-48b8-8714-48aa75d5fcf0',
        edit_scripture_placement_facility_contact_button: 'c0078946-9fef-4024-a3cd-99c2da367454',
      },
      secured_contact_delete_controls: {
        delete_aux_facility_contact_button: '83f4da16-4d37-4961-b854-f02f8fe0b2d3',
        delete_scripture_placement_facility_contact_button: '36348946-0b1e-4d95-ba24-9f5cc9da95ff',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteLocationContact: 'scriptureDistribution/addLocationContact',
      loadLocationContacts: 'scriptureDistribution/loadLocationContacts',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedLocationContact: 'user/setSelectedLocationContact',
    }),
    async handleAddContactClick() {
      await this.setSelectedLocationContact(null)
      this.$router.push('/programs/sd/location-add-contact')
    },
    async handleContactEditRequest(ct) {
      await this.setSelectedLocationContact(ct)
      this.$router.push('/programs/sd/location-add-contact')
    },
    async handleContactProfileRequest(ct) {
      await this.setSelectedIndividualKey(ct.ixo_ind_key)
      this.$router.push('/programs/mb/non-member-profile-sd')
    },
    async handleDeleteRequest(ct) {
      ct.ind_delete_flag = true
      ct.ixo_delete_flag = true
      ct.ind_key = ct.ixo_ind_key
      ct.ixo_user = this.userId
      ct.ind_user = this.userId
      let result = true
      this.setLoadingStatus(true)
      if (ct.primary_ind_word === 'Yes' && this.locationContacts.length > 0) {
        ct.primary = true
        let newprimary = this.locationContacts.filter(oc => {
          return oc.primary_ind_word === ''
        })
        ct.new_primary_key = (!!newprimary && newprimary.length > 0) ? newprimary[0].ixo_ind_key : null
      }
      await Promise.all([(result = await this.deleteLocationContact(ct)), await this.pageLoad()]).then(() => {
        this.$swal({
          icon: result ? 'success' : 'error',
          showCancelButton: false,
          text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
          confirmButtonText: this.translations.tcOk,
        })
      })
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
        await this.loadLocationContacts(this.userSelectedLocationKey),
      ]).then(() => {
        this.backJumpObj.location = this.location.org_name
        this.backJumpObj.link = '/programs/sd/location-profile'
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'scriptureDistribution/locationDetail',
      locationContacts: 'scriptureDistribution/locationContacts',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
  },
  async created() {
    if (!this.userSelectedLocationKey) {
      this.$router.push('/programs/sd/scripture-distribution')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-location', 'quick-jump-locations', 'service-card'),
      this.getComponentTranslations(
        'common.address-types',
        'common.attire',
        'common.church-service-type',
        'common.days',
        'common.email-types',
        'common.fax-types',
        'common.individual-relationship-type',
        'common.months-abbreviations',
        'common.phone-types',
      ),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)
    })
  },
  components: {
    BackJump: BackJump,
    ServiceCard: ServiceCard,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
